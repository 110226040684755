export const groupMenuItems = [
	{
		id: 1,
		seo: 'svi-djelovi',
		title: 'Svi djelovi',
		iconSvg: 'shop/svi-djelovi.svg'
	},
	{
		id: 2,
		seo: 'gume',
		title: 'Gume',
		iconSvg: 'shop/gume.svg'
	},
	{
		id: 3,
		seo: 'akumulatori',
		title: 'Akumulatori',
		iconSvg: 'shop/akumulatori.svg'
	},
	{
		id: 4,
		seo: 'ulja-i-maziva',
		title: 'Ulja i maziva',
		iconSvg: 'shop/ulja-i-maziva.svg'
	},
	{
		id: 5,
		seo: 'filteri',
		title: 'Filteri',
		iconSvg: 'shop/filteri.svg'
	},
	{
		id: 6,
		seo: 'karoserija',
		title: 'Karoserija',
		iconSvg: 'shop/karoserija.svg'
	},
	{
		id: 7,
		seo: 'univerzalno',
		title: 'Univerzalno',
		iconSvg: 'shop/univerzalno.svg'
	},
	{
		id: 8,
		seo: 'alati-i-oprema',
		title: 'Alati i oprema',
		iconSvg: 'shop/alati-i-oprema.svg'
	},
]

export const urlPartsDefault = {
	'grupa': {
		key: 'group',
		position: 1
	},
	'kategorija': {
		key: 'category',
		position: 2
	},
	'proizvodjac': {
		key: 'car_brand',
		position: 3
	},
	'model': {
		key: 'car_model',
		position: 4
	},
	'godina': {
		key: 'year',
		position: 5
	},
	'tip-vozila': {
		key: 'vehicle_type',
		position: 6
	},
	'cijena': {
		key: 'sortByPrice',
		position: 7
	},
	'strana': {
		key: 'page',
		position: 8
	},
}

export const urlPartsGume = {
	'sirina': {
		key: 'width',
		position: 1
	},
	'visina': {
		key: 'height',
		position: 2
	},
	'promjer': {
		key: 'diameter',
		position: 3
	},
	'sezona': {
		key: 'season',
		position: 4
	},
	'tip-vozila': {
		key: 'tire_vehicle_type',
		position: 5
	},
	'proizvodjac': {
		key: 'manufacturer',
		position: 6
	},
	'cijena': {
		key: 'sortByPrice',
		position: 7
	},
	'strana': {
		key: 'page',
		position: 8
	},
}

export const urlPartsAkumulatori = {
	napon: {
		key: 'voltage',
		position: 4
	},
	kapacitet: {
		key: 'capacity',
		position: 5
	},
	sirina: {
		key: 'width',
		position: 1
	},
	visina: {
		key: 'height',
		position: 2
	},
	duzina: {
		key: 'length',
		position: 3
	},
	proizvodjac: {
		key: 'manufacturer',
		position: 6
	},
	'cijena': {
		key: 'sortByPrice',
		position: 7
	},
	'strana': {
		key: 'page',
		position: 8
	},
}

export const urlPartsOstalo = {
	kategorija: {
		key: 'category',
		position: 1
	},
	proizvodjac: {
		key: 'manufacturer',
		position: 2
	},
	'cijena': {
		key: 'sortByPrice',
		position: 3
	},
	'strana': {
		key: 'page',
		position: 4
	},
}

export const getShouldFetchVehicleParts = ({ group, urlValues, filteredFields }) => {
	if (group === 'svi-djelovi') {
		return urlValues && Object.keys(urlPartsDefault).filter((segmentKey) => segmentKey !== 'strana' && segmentKey !== 'model' && segmentKey !== 'cijena').every((segmentKey) => {
			return urlValues[urlPartsDefault[segmentKey].key]
		})
	}

	return true
}

export const getUrlValues = ({ group, urlParts }) => {
	if (!group || group === 'svi-djelovi') {
		const values = { page: 1, group: '', category: '', car_brand: '', car_model: '', year: '', vehicle_type: '', sortByPrice: '' };

			(urlParts || []).forEach((segment) => {
				Object.keys(urlPartsDefault).forEach((key) => {
					if (segment.includes(key)) {
						values[urlPartsDefault[key].key] = segment.replace(`${key}-`, '')
					}
				})

			})

		return values
	}

	if (group === 'gume') {
		const values = { page: 1, width: '', height: '', diameter: '', season: '', tire_vehicle_type: '', manufacturer: '', sortByPrice: '' };

		(urlParts || []).forEach((segment) => {
			Object.keys(urlPartsGume).forEach((key) => {
				if (segment.includes(key)) {
					values[urlPartsGume[key].key] = segment.replace(`${key}-`, '')
				}
			})
		})

		return values
	}

	if (group === 'akumulatori') {
		const values = { page: 1, width: '', height: '', length: '', voltage: '', capacity: '', manufacturer: '', sortByPrice: '' };

		(urlParts || []).forEach((segment) => {
			Object.keys(urlPartsAkumulatori).forEach((key) => {
				if (segment.includes(key)) {
					values[urlPartsAkumulatori[key].key] = segment.replace(`${key}-`, '')
				}
			})
		})

		return values
	}

	if (group) {
		const values = { page: 1, category: '', manufacturer: '', sortByPrice: '' };

		(urlParts || []).forEach((segment) => {
			Object.keys(urlPartsOstalo).forEach((key) => {
				if (segment.includes(key)) {
					values[urlPartsOstalo[key].key] = segment.replace(`${key}-`, '')
				}
			})
		})

		return values
	}
}

export const getUrlParts = (group, values) => {
	if (!group || group === 'svi-djelovi') {
		let parts = Array.from({ length: Object.keys(urlPartsDefault).length }, () => '')

		console.log('getUrlParts values', values)
		Object.keys(urlPartsDefault).forEach((key) => {
			const value = values[urlPartsDefault[key].key]

			console.log('getUrlParts key', key)
			if (value && Number(value) !== -1 && !(key === 'strana' && Number(value) === 1)) {
				// segments.push(`${key}-${value}`)
				parts[urlPartsDefault[key].position - 1] = [key, value, urlPartsDefault[key].key]
			}
		})

		parts = parts.filter((part) => part)

		return parts
	}

	if (group === 'gume') {
		let parts = Array.from({ length: Object.keys(urlPartsGume).length }, () => '')

		Object.keys(urlPartsGume).forEach((key) => {
			const value = values[urlPartsGume[key].key]

			if (value && Number(value) !== -1 && !(key === 'strana' && Number(value) === 1)) {
				parts[urlPartsGume[key].position - 1] = [key, value, urlPartsGume[key].key]
			}
		})

		parts = parts.filter((part) => part)

		return parts
	}

	if (group === 'akumulatori') {
		let parts = Array.from({ length: Object.keys(urlPartsAkumulatori).length }, () => '')

		Object.keys(urlPartsAkumulatori).forEach((key) => {
			const value = values[urlPartsAkumulatori[key].key]

			if (value && Number(value) !== -1 && !(key === 'strana' && Number(value) === 1)) {
				parts[urlPartsAkumulatori[key].position - 1] = [key, value, urlPartsAkumulatori[key].key]
			}
		})

		parts = parts.filter((part) => part)

		return parts
	}

	if (group) {
		let parts = Array.from({ length: Object.keys(urlPartsOstalo).length }, () => '')

		Object.keys(urlPartsOstalo).forEach((key) => {
			const value = values[urlPartsOstalo[key].key]

			if (value && Number(value) !== -1 && !(key === 'strana' && Number(value) === 1)) {
				parts[urlPartsOstalo[key].position - 1] = [key, value, urlPartsOstalo[key].key]
			}
		})

		parts = parts.filter((part) => part)

		return parts
	}
}

export const getUrl = (group, values) => {
	if (!group) {
		console.log('getUrl values', values)

		if (values['text-filter']) {
			return { asUrl: `/novi-djelovi?text-filter=${values['text-filter']}`, href: { pathname: '/vehicleParts', query: { 'text-filter': values['text-filter'] } } }
		}

		return { asUrl: '/novi-djelovi', href: { pathname: '/vehicleParts' } }
	}

	const urlParts = getUrlParts(group, values)?.map(([key, value]) => `${key}-${value}`) || []

	const asUrl = `/novi-djelovi/${group !== 'svi-djelovi' ? group : ''}` + `${urlParts.length ? ('/' + urlParts.join('/')) : ''}`

	const href = {
		pathname: '/vehicleParts',
		query: {
			'0': urlParts.join('/')
		}
	}

	if (group !== 'svi-djelovi') href.query.group = group

	return { asUrl, href }
}

export const getListComponent = (values) => {
	if (!values) return 'group'

	if (values.group && values.category && values.manufacturer && values.model && values.year && values.tipVozila) {
		return 'parts'
	}

	if (values.group && values.category && values.manufacturer && values.model && values.year && !values.tipVozila) {
		return 'tipVozila'
	}

	if (values.group && values.category && values.manufacturer && values.year && !values.tipVozila && !values.model) {
		return 'tipVozila'
	}

	if (values.group && values.category && values.manufacturer && !values.model && !values.year && !values.tipVozila) {
		return 'year'
	}

	if (values.group && values.category && !values.manufacturer && !values.model && !values.year && !values.tipVozila) {
		return 'manufacturer'
	}

	if (values.group && !values.category && !values.manufacturer && !values.model && !values.year && !values.tipVozila) {
		return 'category'
	}

	if (!values.group && !values.category && !values.manufacturer && !values.model && !values.year && !values.tipVozila) {
		return 'group'
	}

	if (!values.group && !values.category && values.manufacturer && !values.model && !values.year && !values.tipVozila) {
		return 'group'
	}

	if (!values.group && !values.category && values.manufacturer && values.model && !values.year && !values.tipVozila) {
		return 'group'
	}

	if (values.group && !values.category && values.manufacturer && !values.model && !values.year && !values.tipVozila) {
		return 'category'
	}

	if (values.group && !values.category && values.manufacturer && values.model && !values.year && !values.tipVozila) {
		return 'category'
	}

	return 'parts'
}

export const getFilteredFieldsPayload = ({ group, urlValues }) => {
	if (!group || group === 'svi-djelovi') {
		const payload = {}

		if (urlValues?.group) payload[urlPartsDefault.grupa.key] = urlValues.group
		if (urlValues?.category) payload[urlPartsDefault.kategorija.key] = urlValues.category
		if (urlValues?.car_brand) payload[urlPartsDefault.proizvodjac.key] = urlValues.car_brand
		if (urlValues?.car_model) payload[urlPartsDefault.model.key] = urlValues.car_model
		if (urlValues?.year) payload[urlPartsDefault.godina.key] = urlValues.year
		if (urlValues?.vehicle_type) payload[urlPartsDefault["tip-vozila"].key] = urlValues.vehicle_type

		return payload
	}

	if (group === 'gume') {
		const payload = {}

		if (urlValues?.width) payload[urlPartsGume.sirina.key] = urlValues.width
		if (urlValues?.height) payload[urlPartsGume.visina.key] = urlValues.height
		if (urlValues?.diameter) payload[urlPartsGume.promjer.key] = urlValues.diameter
		if (urlValues?.season) payload[urlPartsGume.sezona.key] = urlValues.season
		if (urlValues?.tire_vehicle_type) payload[urlPartsGume["tip-vozila"].key] = urlValues.tire_vehicle_type
		if (urlValues?.manufacturer) payload[urlPartsGume.proizvodjac.key] = urlValues.manufacturer

		return payload
	}

	if (group === 'akumulatori') {
		const payload = {}

		if (urlValues?.voltage) payload[urlPartsAkumulatori.napon.key] = urlValues.voltage
		if (urlValues?.capacity) payload[urlPartsAkumulatori.kapacitet.key] = urlValues.capacity
		if (urlValues?.width) payload[urlPartsAkumulatori.sirina.key] = urlValues.width
		if (urlValues?.height) payload[urlPartsAkumulatori.visina.key] = urlValues.height
		if (urlValues?.length) payload[urlPartsAkumulatori.duzina.key] = urlValues.length
		if (urlValues?.manufacturer) payload[urlPartsAkumulatori.proizvodjac.key] = urlValues.manufacturer

		return payload
	}

	if (group) {
		const payload = {}

		if (urlValues?.category) payload[urlPartsOstalo.kategorija.key] = urlValues.category
		if (urlValues?.manufacturer) payload[urlPartsOstalo.proizvodjac.key] = urlValues.manufacturer

		return payload
	}
}
